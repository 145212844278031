/**
 * Layout
 */


@use "sass:math";

/* ==========================================================================
   Scaffolding - Breakpoints
   ========================================================================== */

/* Next
   ========================================================================== */
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);

  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

/* Min
   ========================================================================== */
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

/* Max
   ========================================================================== */
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);

  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

/* Up
   ========================================================================== */
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/* Down
   ========================================================================== */
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

/* Only
   ========================================================================== */
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($name, $breakpoints) {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

/* Between
   ========================================================================== */
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($lower, $breakpoints) {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}


$grid-breakpoints: (
        xs: 480px,
        sm: 768px,
        md: 992px,
        lg: 1200px,
        xl: 1470px
);

$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 100%
);

$grid-columns-count: 12;
$grid-gutter-width-base: 1.5rem;

$grid-gutter-widths: (
        xs: $grid-gutter-width-base,
        sm: $grid-gutter-width-base,
        md: $grid-gutter-width-base,
        lg: $grid-gutter-width-base,
        xl: $grid-gutter-width-base
);

.row {
  display: flex;
  flex-flow: row wrap;
  @each $breakpoint in map-keys($grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($grid-gutter-widths, $breakpoint);
      margin-left: calc(#{$gutter} / -2);
      margin-right: calc(#{$gutter} / -2);
    }
  }
}

.col {
  position: relative;
  flex-grow: 1;
  flex-basis: 100%;
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  float: left; /* fix old school code */
  &.-centered {
    margin: 0 auto;
  }
  @each $breakpoint in map-keys($grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($grid-gutter-widths, $breakpoint);

      padding-left: calc(#{$gutter} / 2);
      padding-right: calc(#{$gutter} / 2);
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @for $i from 1 through $grid-columns-count {
      // Col
      .col-#{$breakpoint}-#{$i} {
        flex: 0 0 percentage(math.div($i, $grid-columns-count));
        width: percentage(math.div($i, $grid-columns-count));
        max-width: percentage(math.div($i, $grid-columns-count));
      }
      // Offset
      .col-offset-#{$breakpoint}-#{$i} {
        margin-left: percentage(math.div($i, $grid-columns-count));
      }
    }
  }
}



.container {
  @include media-breakpoint-down(xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-up(sm) {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 50px;
    padding-right: 50px;
  }
}


/* push */
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 480px) {
  .col-ms-pull-12 {
    right: 100%;
  }
  .col-ms-pull-11 {
    right: 91.66666667%;
  }
  .col-ms-pull-10 {
    right: 83.33333333%;
  }
  .col-ms-pull-9 {
    right: 75%;
  }
  .col-ms-pull-8 {
    right: 66.66666667%;
  }
  .col-ms-pull-7 {
    right: 58.33333333%;
  }
  .col-ms-pull-6 {
    right: 50%;
  }
  .col-ms-pull-5 {
    right: 41.66666667%;
  }
  .col-ms-pull-4 {
    right: 33.33333333%;
  }
  .col-ms-pull-3 {
    right: 25%;
  }
  .col-ms-pull-2 {
    right: 16.66666667%;
  }
  .col-ms-pull-1 {
    right: 8.33333333%;
  }
  .col-ms-pull-0 {
    right: auto;
  }
  .col-ms-push-12 {
    left: 100%;
  }
  .col-ms-push-11 {
    left: 91.66666667%;
  }
  .col-ms-push-10 {
    left: 83.33333333%;
  }
  .col-ms-push-9 {
    left: 75%;
  }
  .col-ms-push-8 {
    left: 66.66666667%;
  }
  .col-ms-push-7 {
    left: 58.33333333%;
  }
  .col-ms-push-6 {
    left: 50%;
  }
  .col-ms-push-5 {
    left: 41.66666667%;
  }
  .col-ms-push-4 {
    left: 33.33333333%;
  }
  .col-ms-push-3 {
    left: 25%;
  }
  .col-ms-push-2 {
    left: 16.66666667%;
  }
  .col-ms-push-1 {
    left: 8.33333333%;
  }
  .col-ms-push-0 {
    left: auto;
  }
  .col-ms-offset-12 {
    margin-left: 100%;
  }
  .col-ms-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-ms-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-ms-offset-9 {
    margin-left: 75%;
  }
  .col-ms-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-ms-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-ms-offset-6 {
    margin-left: 50%;
  }
  .col-ms-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-ms-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-ms-offset-3 {
    margin-left: 25%;
  }
  .col-ms-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-ms-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-ms-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}



/* Breakpoints */
/*$breakpoint_ms: 'screen' 480px; // ms
$breakpoint_sm: 'screen' 768px; // sm
$breakpoint_md: 'screen' 992px; // md
$breakpoint_lg: 'screen' 1400px; // lg
$breakpoint_xl: 'screen' 1800px; // xl
$breakpoint_xxl: 'screen' 2000px; // xl*/

/* Container */

/*.container {
  @extend .clearfix;
  //max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  padding: 0 10px;
  @include breakpoint($breakpoint_sm) {
    padding: 0 20px;
  }
  @include breakpoint($breakpoint_md) {
    padding: 0 30px;
  }
  @include breakpoint($breakpoint_lg) {
    padding: 0 40px;
  }
  @include breakpoint($breakpoint_xl) {
    padding: 0 70px;
    max-width: 1800px;
  }
  @include breakpoint($breakpoint_xxl) {
    padding: 0 90px;
    max-width: 1900px;
  }
}

header, footer, .main {
  @extend .clearfix;
}*/


/* Bootstrap grid system */
/*
.row {
  margin-left: -0.7rem;
  margin-right: -0.7rem;
}
.col-xs-1, .col-ms-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-ms-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-ms-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-ms-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-ms-5, .col-md-5, .col-lg-5, .col-xs-6, .col-ms-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-ms-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-ms-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-ms-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-ms-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-ms-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-ms-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 480px) {
  .col-ms-1, .col-ms-2, .col-ms-3, .col-ms-4, .col-ms-5, .col-ms-6, .col-ms-7, .col-ms-8, .col-ms-9, .col-ms-10, .col-ms-11, .col-ms-12 {
    float: left;
  }
  .col-ms-12 {
    width: 100%;
  }
  .col-ms-11 {
    width: 91.66666667%;
  }
  .col-ms-10 {
    width: 83.33333333%;
  }
  .col-ms-9 {
    width: 75%;
  }
  .col-ms-8 {
    width: 66.66666667%;
  }
  .col-ms-7 {
    width: 58.33333333%;
  }
  .col-ms-6 {
    width: 50%;
  }
  .col-ms-5 {
    width: 41.66666667%;
  }
  .col-ms-4 {
    width: 33.33333333%;
  }
  .col-ms-3 {
    width: 25%;
  }
  .col-ms-2 {
    width: 16.66666667%;
  }
  .col-ms-1 {
    width: 8.33333333%;
  }
  .col-ms-pull-12 {
    right: 100%;
  }
  .col-ms-pull-11 {
    right: 91.66666667%;
  }
  .col-ms-pull-10 {
    right: 83.33333333%;
  }
  .col-ms-pull-9 {
    right: 75%;
  }
  .col-ms-pull-8 {
    right: 66.66666667%;
  }
  .col-ms-pull-7 {
    right: 58.33333333%;
  }
  .col-ms-pull-6 {
    right: 50%;
  }
  .col-ms-pull-5 {
    right: 41.66666667%;
  }
  .col-ms-pull-4 {
    right: 33.33333333%;
  }
  .col-ms-pull-3 {
    right: 25%;
  }
  .col-ms-pull-2 {
    right: 16.66666667%;
  }
  .col-ms-pull-1 {
    right: 8.33333333%;
  }
  .col-ms-pull-0 {
    right: auto;
  }
  .col-ms-push-12 {
    left: 100%;
  }
  .col-ms-push-11 {
    left: 91.66666667%;
  }
  .col-ms-push-10 {
    left: 83.33333333%;
  }
  .col-ms-push-9 {
    left: 75%;
  }
  .col-ms-push-8 {
    left: 66.66666667%;
  }
  .col-ms-push-7 {
    left: 58.33333333%;
  }
  .col-ms-push-6 {
    left: 50%;
  }
  .col-ms-push-5 {
    left: 41.66666667%;
  }
  .col-ms-push-4 {
    left: 33.33333333%;
  }
  .col-ms-push-3 {
    left: 25%;
  }
  .col-ms-push-2 {
    left: 16.66666667%;
  }
  .col-ms-push-1 {
    left: 8.33333333%;
  }
  .col-ms-push-0 {
    left: auto;
  }
  .col-ms-offset-12 {
    margin-left: 100%;
  }
  .col-ms-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-ms-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-ms-offset-9 {
    margin-left: 75%;
  }
  .col-ms-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-ms-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-ms-offset-6 {
    margin-left: 50%;
  }
  .col-ms-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-ms-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-ms-offset-3 {
    margin-left: 25%;
  }
  .col-ms-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-ms-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-ms-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}*/

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-ms,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-ms-block,
.visible-ms-inline,
.visible-ms-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 479px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 479px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 479px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 479px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms {
    display: block !important;
  }
  table.visible-ms {
    display: table;
  }
  tr.visible-ms {
    display: table-row !important;
  }
  th.visible-ms,
  td.visible-ms {
    display: table-cell !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms-block {
    display: block !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms-inline {
    display: inline !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}

/* IPE support */
.panels-ipe-portlet-wrapper {
  clear: both;
}
div[class*="col-sm-"] .panel-pane,
div[class*="col-sm-"].panels-ipe-portlet-wrapper {
  clear: none;
}
@media (min-width: 992px) {
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-11 {
    width: 91.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-10 {
    width: 83.33333333%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-9 {
    width: 75%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-8 {
    width: 66.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-7 {
    width: 58.33333333%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-6 {
    width: 50%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-5 {
    width: 41.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-4 {
    width: 33.33333333%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-3 {
    width: 25%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-2 {
    width: 16.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-1 {
    width: 8.33333333%;
  }
}

/* Custom clear */
.force-clear {
  clear: both !important;
}
.flex-clear {
  flex-basis: 100%;
  height: 0;
}