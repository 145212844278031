/**
 * Typography
 */



@font-face {
  font-family: 'Brandon';
  src: url('../fonts/brandon_bld-webfont.woff2') format('woff2'),
  url('../fonts/brandon_bld-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../fonts/brandon_light-webfont.woff2') format('woff2'),
  url('../fonts/brandon_light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../fonts/brandon_med-webfont.woff2') format('woff2'),
  url('../fonts/brandon_med-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../fonts/brandon_reg_it-webfont.woff2') format('woff2'),
  url('../fonts/brandon_reg_it-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon';
  src: url('../fonts/brandon_reg-webfont.woff2') format('woff2'),
  url('../fonts/brandon_reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('../fonts/lora-italic-webfont.woff2') format('woff2'),
  url('../fonts/lora-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

/* Variables */
$base-font-size: 17px;
$base-line-height: 1.47rem;
$base-font-family: Brandon, Helvetica, Arial, sans-serif;

// 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
//    http://clagnut.com/blog/348/#c790
// 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
//    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
html {
  font-size: 106.25%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
  @include media-breakpoint-up(lg) {
    font-size: 115%;
  }
  @include media-breakpoint-up(xl) {
    font-size: 150%;
  }
  /*@include media-breakpoint-up(xxl) {
    font-size: 185%;
  }*/
}

// Addresses font-family inconsistency between 'textarea' and other form elements.
html,
button,
input,
select,
textarea,
body {
  font-family: $base-font-family;
  line-height: $base-line-height;
}

body{
}

h1 {
  font-size: 2.1rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  margin: 0 0 1.2rem 0;
  font-weight: 800;
  &::after {
    content: "";
    height: 3px;
    width: 50px;
    margin-top: 15px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
    background: $lightblue;
    display: block;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
}

h2 {
  margin: 0 0 0.9rem 0;
  font-size: 1rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
  &::after {
    content: "";
    height: 3px;
    width: 50px;
    margin-top: 5px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
    background: $lightblue;
    display: block;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
}

h3 {
  margin: 0 0 0.4rem 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

p {
  margin: 0 0 1rem 0;
}

strong {
  font-weight: 500;
}

ul,
ol {
  margin: 0 0 1rem 1rem;
}

/* Base link settings */

a {
  color: $base-link-color;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $base-link-hover-color;
  }
  &:active {
    color: $base-link-active-color;
  }
}

h2 a,
h3 a {
  color: $darkblue;
}

html {
  color: $base-text-color;
}

*::selection {
  color: #fff;
  background-color: $lightblue;
}
*::-moz-selection {
  color: #fff;
  background-color: $lightblue;
}