// Origin: https://github.com/JohnAlbin/normalize.css-with-sass-or-compass/blob/normalize-with-compass/normalize.scss

//@import "compass/support";
//@import "compass/css3/box-sizing";

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

// HTML5 display definitions

// Corrects block display not defined in IE6/7/8/9 & FF3
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

// Corrects inline-block display not defined in IE6/7/8/9 & FF3
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

// Prevents modern browsers from displaying 'audio' without controls
// Remove excess height in iOS5 devices
audio:not([controls]) {
  display: none;
  height: 0;
}

// Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
// Known issue: no IE6 support
[hidden] {
  display: none;
}

// Addresses margins handled incorrectly in IE6/7
body {
  margin: 0;
}

// Links

// Addresses outline displayed oddly in Chrome
a:focus {
  outline: thin dotted;
}

// Improves readability when focused and also mouse hovered in all browsers
// people.opera.com/patrickl/experiments/keyboard/test
a:hover,
a:active {
  outline: 0;
}

// Addresses styling not present in IE7/8/9, S5, Chrome
abbr[title] {
  border-bottom: 1px dotted;
}

// Addresses style set to 'bolder' in FF3+, S4/5, Chrome
b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

// Addresses styling not present in S5, Chrome
dfn {
  font-style: italic;
}

// Addresses styling not present in IE6/7/8/9
mark {
  background: #ff0;
  color: #000;
}

// Addresses margins set differently in IE6/7
p,
pre {
  margin: 0;
}

// Improves readability of pre-formatted text in all browsers
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

// 1. Addresses CSS quotes not supported in IE6/7
// 2. Addresses quote property not supported in S4
q {
  quotes: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

small {
  font-size: 75%;
}

// Prevents sub and sup affecting line-height in all browsers
// gist.github.com/413930
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Lists

// Addresses margins set differently in IE6/7
dl,
menu,
ol,
ul,
dd {
  margin: 0;
}

// Addresses paddings set differently in IE6/7
menu,
ol,
ul {
  padding: 0;
}

// Corrects list images handled incorrectly in IE7
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

// Embedded content

// 1. Removes border when inside 'a' element in IE6/7/8/9, FF3
// 2. Improves image quality when scaled in IE7
//    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
img {
  border: 0;
  display: block;
  // 1
  -ms-interpolation-mode: bicubic;
  // 2
}

// Corrects overflow displayed oddly in IE9
svg:not(:root) {
  overflow: hidden;
}

// Figures

// Addresses margin not present in IE6/7/8/9, S5, O11
figure {
  margin: 0;
}

// Forms

// Corrects margin displayed oddly in IE6/7
form {
  margin: 0;
}

// Define consistent border, margin, and padding
fieldset {
  border-color: #c0c0c0;
  margin: 0 2px;
}

// 1. Corrects color not being inherited in IE6/7/8/9
// 2. Corrects text not wrapping in FF3
legend {
  border: 0;
  // 1
  padding: 0;
  white-space: normal;
  // 2
}

// 1. Corrects font size not being inherited in all browsers
// 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
// 3. Improves appearance and consistency in all browsers
button,
input,
select,
textarea {
  font-size: 100%;
  // 1
  margin: 0;
  // 2
  vertical-align: baseline;
  // 3
}

// Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
button,
input {
  line-height: normal;
}

// 1. Improves usability and consistency of cursor style between image-type 'input' and others
// 2. Corrects inability to style clickable 'input' types in iOS
//    Known issue: inner spacing remains in IE6
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  // 1
  -webkit-appearance: button;
  // 2
}

// Re-set default cursor for disabled elements
button[disabled],
input[disabled] {
  cursor: default;
}

// 1. Addresses box sizing set to content-box in IE8/9
// 2. Removes excess padding in IE8/9
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  // 1
  padding: 0;
  // 2
}

// 1. Addresses appearance set to searchfield in S5, Chrome
// 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
input[type="search"] {
  -webkit-appearance: textfield;
  // 1
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  // 2
}

// Removes inner padding and search cancel button in S5, Chrome on OS X
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

// Removes inner padding and border in FF3+
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// 1. Removes default vertical scrollbar in IE6/7/8/9
// 2. Improves readability and alignment in all browsers
textarea {
  overflow: auto;
  // 1
  vertical-align: top;
  // 2
}

// Tables

// Remove most spacing between table cells
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

// Div's with onclick event should use the right cursor.
html.js div[onclick] {
  cursor: pointer;
}