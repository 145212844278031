/**
 * Print style file
 *
 * This file is compiled into print.css
 */

/* ---------- Compass Extensions ---------- */

@import 'base/colors';
//@import 'toolkit';
//@import 'breakpoint';

/* ---------- Import base ---------- */

@import 'base/system/normalize';
@import 'base/grid';
@import 'base/typography';

/* ---------- Hide certain elements ---------- */
header,
.primary-navigation,
.tabs, .footer-invisible,
footer, .sticky-header{
  display: none !important;
}


td {
  padding: 3px;
}