/**
 * Print style file
 *
 * This file is compiled into print.css
 */
/* ---------- Compass Extensions ---------- */
/**
 * Colors
 *
 * All colors used in the project should be declared here.
 */
/* ---------- Import base ---------- */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

body {
  margin: 0;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

p,
pre {
  margin: 0;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

small {
  font-size: 75%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl,
menu,
ol,
ul,
dd {
  margin: 0;
}

menu,
ol,
ul {
  padding: 0;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

img {
  border: 0;
  display: block;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border-color: #c0c0c0;
  margin: 0 2px;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix {
  *zoom: 1;
}
.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

html.js div[onclick] {
  cursor: pointer;
}

/**
 * Layout
 */
/* ==========================================================================
   Scaffolding - Breakpoints
   ========================================================================== */
/* Next
   ========================================================================== */
/* Min
   ========================================================================== */
/* Max
   ========================================================================== */
/* Up
   ========================================================================== */
/* Down
   ========================================================================== */
/* Only
   ========================================================================== */
/* Between
   ========================================================================== */
.row {
  display: flex;
  flex-flow: row wrap;
}
@media (min-width: 480px) {
  .row {
    margin-left: calc(1.5rem / -2);
    margin-right: calc(1.5rem / -2);
  }
}
@media (min-width: 768px) {
  .row {
    margin-left: calc(1.5rem / -2);
    margin-right: calc(1.5rem / -2);
  }
}
@media (min-width: 992px) {
  .row {
    margin-left: calc(1.5rem / -2);
    margin-right: calc(1.5rem / -2);
  }
}
@media (min-width: 1200px) {
  .row {
    margin-left: calc(1.5rem / -2);
    margin-right: calc(1.5rem / -2);
  }
}
@media (min-width: 1470px) {
  .row {
    margin-left: calc(1.5rem / -2);
    margin-right: calc(1.5rem / -2);
  }
}

.col {
  position: relative;
  flex-grow: 1;
  flex-basis: 100%;
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  float: left; /* fix old school code */
}
.col.-centered {
  margin: 0 auto;
}
@media (min-width: 480px) {
  .col {
    padding-left: calc(1.5rem / 2);
    padding-right: calc(1.5rem / 2);
  }
}
@media (min-width: 768px) {
  .col {
    padding-left: calc(1.5rem / 2);
    padding-right: calc(1.5rem / 2);
  }
}
@media (min-width: 992px) {
  .col {
    padding-left: calc(1.5rem / 2);
    padding-right: calc(1.5rem / 2);
  }
}
@media (min-width: 1200px) {
  .col {
    padding-left: calc(1.5rem / 2);
    padding-right: calc(1.5rem / 2);
  }
}
@media (min-width: 1470px) {
  .col {
    padding-left: calc(1.5rem / 2);
    padding-right: calc(1.5rem / 2);
  }
}

@media (min-width: 480px) {
  .col-xs-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .col-xs-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .col-offset-xs-3 {
    margin-left: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .col-xs-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .col-offset-xs-6 {
    margin-left: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .col-xs-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .col-offset-xs-9 {
    margin-left: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .col-xs-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-offset-xs-11 {
    margin-left: 91.6666666667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .col-offset-xs-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .col-offset-sm-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .col-offset-sm-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .col-offset-sm-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .col-offset-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .col-offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .col-offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .col-offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .col-offset-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .col-offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .col-offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .col-offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .col-offset-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1470px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .col-offset-xl-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .col-offset-xl-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .col-offset-xl-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .col-offset-xl-12 {
    margin-left: 100%;
  }
}
@media (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/* push */
.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 480px) {
  .col-ms-pull-12 {
    right: 100%;
  }
  .col-ms-pull-11 {
    right: 91.66666667%;
  }
  .col-ms-pull-10 {
    right: 83.33333333%;
  }
  .col-ms-pull-9 {
    right: 75%;
  }
  .col-ms-pull-8 {
    right: 66.66666667%;
  }
  .col-ms-pull-7 {
    right: 58.33333333%;
  }
  .col-ms-pull-6 {
    right: 50%;
  }
  .col-ms-pull-5 {
    right: 41.66666667%;
  }
  .col-ms-pull-4 {
    right: 33.33333333%;
  }
  .col-ms-pull-3 {
    right: 25%;
  }
  .col-ms-pull-2 {
    right: 16.66666667%;
  }
  .col-ms-pull-1 {
    right: 8.33333333%;
  }
  .col-ms-pull-0 {
    right: auto;
  }
  .col-ms-push-12 {
    left: 100%;
  }
  .col-ms-push-11 {
    left: 91.66666667%;
  }
  .col-ms-push-10 {
    left: 83.33333333%;
  }
  .col-ms-push-9 {
    left: 75%;
  }
  .col-ms-push-8 {
    left: 66.66666667%;
  }
  .col-ms-push-7 {
    left: 58.33333333%;
  }
  .col-ms-push-6 {
    left: 50%;
  }
  .col-ms-push-5 {
    left: 41.66666667%;
  }
  .col-ms-push-4 {
    left: 33.33333333%;
  }
  .col-ms-push-3 {
    left: 25%;
  }
  .col-ms-push-2 {
    left: 16.66666667%;
  }
  .col-ms-push-1 {
    left: 8.33333333%;
  }
  .col-ms-push-0 {
    left: auto;
  }
  .col-ms-offset-12 {
    margin-left: 100%;
  }
  .col-ms-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-ms-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-ms-offset-9 {
    margin-left: 75%;
  }
  .col-ms-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-ms-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-ms-offset-6 {
    margin-left: 50%;
  }
  .col-ms-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-ms-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-ms-offset-3 {
    margin-left: 25%;
  }
  .col-ms-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-ms-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-ms-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 768px) {
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

/* Breakpoints */
/*$breakpoint_ms: 'screen' 480px; // ms
$breakpoint_sm: 'screen' 768px; // sm
$breakpoint_md: 'screen' 992px; // md
$breakpoint_lg: 'screen' 1400px; // lg
$breakpoint_xl: 'screen' 1800px; // xl
$breakpoint_xxl: 'screen' 2000px; // xl*/
/* Container */
/*.container {
  @extend .clearfix;
  //max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  padding: 0 10px;
  @include breakpoint($breakpoint_sm) {
    padding: 0 20px;
  }
  @include breakpoint($breakpoint_md) {
    padding: 0 30px;
  }
  @include breakpoint($breakpoint_lg) {
    padding: 0 40px;
  }
  @include breakpoint($breakpoint_xl) {
    padding: 0 70px;
    max-width: 1800px;
  }
  @include breakpoint($breakpoint_xxl) {
    padding: 0 90px;
    max-width: 1900px;
  }
}

header, footer, .main {
  @extend .clearfix;
}*/
/* Bootstrap grid system */
/*
.row {
  margin-left: -0.7rem;
  margin-right: -0.7rem;
}
.col-xs-1, .col-ms-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-ms-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-ms-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-ms-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-ms-5, .col-md-5, .col-lg-5, .col-xs-6, .col-ms-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-ms-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-ms-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-ms-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-ms-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-ms-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-ms-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 480px) {
  .col-ms-1, .col-ms-2, .col-ms-3, .col-ms-4, .col-ms-5, .col-ms-6, .col-ms-7, .col-ms-8, .col-ms-9, .col-ms-10, .col-ms-11, .col-ms-12 {
    float: left;
  }
  .col-ms-12 {
    width: 100%;
  }
  .col-ms-11 {
    width: 91.66666667%;
  }
  .col-ms-10 {
    width: 83.33333333%;
  }
  .col-ms-9 {
    width: 75%;
  }
  .col-ms-8 {
    width: 66.66666667%;
  }
  .col-ms-7 {
    width: 58.33333333%;
  }
  .col-ms-6 {
    width: 50%;
  }
  .col-ms-5 {
    width: 41.66666667%;
  }
  .col-ms-4 {
    width: 33.33333333%;
  }
  .col-ms-3 {
    width: 25%;
  }
  .col-ms-2 {
    width: 16.66666667%;
  }
  .col-ms-1 {
    width: 8.33333333%;
  }
  .col-ms-pull-12 {
    right: 100%;
  }
  .col-ms-pull-11 {
    right: 91.66666667%;
  }
  .col-ms-pull-10 {
    right: 83.33333333%;
  }
  .col-ms-pull-9 {
    right: 75%;
  }
  .col-ms-pull-8 {
    right: 66.66666667%;
  }
  .col-ms-pull-7 {
    right: 58.33333333%;
  }
  .col-ms-pull-6 {
    right: 50%;
  }
  .col-ms-pull-5 {
    right: 41.66666667%;
  }
  .col-ms-pull-4 {
    right: 33.33333333%;
  }
  .col-ms-pull-3 {
    right: 25%;
  }
  .col-ms-pull-2 {
    right: 16.66666667%;
  }
  .col-ms-pull-1 {
    right: 8.33333333%;
  }
  .col-ms-pull-0 {
    right: auto;
  }
  .col-ms-push-12 {
    left: 100%;
  }
  .col-ms-push-11 {
    left: 91.66666667%;
  }
  .col-ms-push-10 {
    left: 83.33333333%;
  }
  .col-ms-push-9 {
    left: 75%;
  }
  .col-ms-push-8 {
    left: 66.66666667%;
  }
  .col-ms-push-7 {
    left: 58.33333333%;
  }
  .col-ms-push-6 {
    left: 50%;
  }
  .col-ms-push-5 {
    left: 41.66666667%;
  }
  .col-ms-push-4 {
    left: 33.33333333%;
  }
  .col-ms-push-3 {
    left: 25%;
  }
  .col-ms-push-2 {
    left: 16.66666667%;
  }
  .col-ms-push-1 {
    left: 8.33333333%;
  }
  .col-ms-push-0 {
    left: auto;
  }
  .col-ms-offset-12 {
    margin-left: 100%;
  }
  .col-ms-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-ms-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-ms-offset-9 {
    margin-left: 75%;
  }
  .col-ms-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-ms-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-ms-offset-6 {
    margin-left: 50%;
  }
  .col-ms-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-ms-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-ms-offset-3 {
    margin-left: 25%;
  }
  .col-ms-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-ms-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-ms-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}*/
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-ms,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-ms-block,
.visible-ms-inline,
.visible-ms-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 479px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 479px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 479px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 479px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms {
    display: block !important;
  }
  table.visible-ms {
    display: table;
  }
  tr.visible-ms {
    display: table-row !important;
  }
  th.visible-ms,
  td.visible-ms {
    display: table-cell !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms-block {
    display: block !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms-inline {
    display: inline !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .visible-ms-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
/* IPE support */
.panels-ipe-portlet-wrapper {
  clear: both;
}

div[class*=col-sm-] .panel-pane,
div[class*=col-sm-].panels-ipe-portlet-wrapper {
  clear: none;
}

@media (min-width: 992px) {
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-11 {
    width: 91.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-10 {
    width: 83.33333333%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-9 {
    width: 75%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-8 {
    width: 66.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-7 {
    width: 58.33333333%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-6 {
    width: 50%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-5 {
    width: 41.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-4 {
    width: 33.33333333%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-3 {
    width: 25%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-2 {
    width: 16.66666667%;
  }
  .panels-ipe-editing div.panels-ipe-portlet-wrapper.col-sm-1 {
    width: 8.33333333%;
  }
}
/* Custom clear */
.force-clear {
  clear: both !important;
}

.flex-clear {
  flex-basis: 100%;
  height: 0;
}

/**
 * Typography
 */
@font-face {
  font-family: "Brandon";
  src: url("../fonts/brandon_bld-webfont.woff2") format("woff2"), url("../fonts/brandon_bld-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/brandon_light-webfont.woff2") format("woff2"), url("../fonts/brandon_light-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/brandon_med-webfont.woff2") format("woff2"), url("../fonts/brandon_med-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/brandon_reg_it-webfont.woff2") format("woff2"), url("../fonts/brandon_reg_it-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Brandon";
  src: url("../fonts/brandon_reg-webfont.woff2") format("woff2"), url("../fonts/brandon_reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lora";
  src: url("../fonts/lora-italic-webfont.woff2") format("woff2"), url("../fonts/lora-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
/* Variables */
html {
  font-size: 106.25%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
  /*@include media-breakpoint-up(xxl) {
    font-size: 185%;
  }*/
}
@media (min-width: 1200px) {
  html {
    font-size: 115%;
  }
}
@media (min-width: 1470px) {
  html {
    font-size: 150%;
  }
}

html,
button,
input,
select,
textarea,
body {
  font-family: Brandon, Helvetica, Arial, sans-serif;
  line-height: 1.47rem;
}

h1 {
  font-size: 2.1rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  margin: 0 0 1.2rem 0;
  font-weight: 800;
}
h1::after {
  content: "";
  height: 3px;
  width: 50px;
  margin-top: 15px;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
  background: #86cdda;
  display: block;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

h2 {
  margin: 0 0 0.9rem 0;
  font-size: 1rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
}
h2::after {
  content: "";
  height: 3px;
  width: 50px;
  margin-top: 5px;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
  background: #86cdda;
  display: block;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

h3 {
  margin: 0 0 0.4rem 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

p {
  margin: 0 0 1rem 0;
}

strong {
  font-weight: 500;
}

ul,
ol {
  margin: 0 0 1rem 1rem;
}

/* Base link settings */
a {
  color: #86cdda;
  text-decoration: none;
}
a:hover, a:focus {
  color: #7abcc8;
}
a:active {
  color: #66a5b0;
}

h2 a,
h3 a {
  color: #185c71;
}

html {
  color: #185c71;
}

*::selection {
  color: #fff;
  background-color: #86cdda;
}

*::-moz-selection {
  color: #fff;
  background-color: #86cdda;
}

/* ---------- Hide certain elements ---------- */
header,
.primary-navigation,
.tabs, .footer-invisible,
footer, .sticky-header {
  display: none !important;
}

td {
  padding: 3px;
}