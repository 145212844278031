/**
 * Colors
 *
 * All colors used in the project should be declared here.
 */
$darkblue: #185c71;
$lightblue: #86cdda;
$orange: #e66541;
$yellow: #fab619;
$grey: #c5d7dc;
$light: #f3f4f0;
$base-text-color: $darkblue;
$white: #ffffff;

$base-link-color: $lightblue;
$base-link-hover-color: #7abcc8;
$base-link-active-color: #66a5b0;
